.loading-container {
  z-index: 5;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  animation: blink 2s linear 0s infinite;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top {
    margin-bottom: -50px;
    background-color: transparent;
    border: 2px solid white;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-radius: 100px;
    width: 100px;
    height: 100px;
    transform: rotateY(0deg) rotate(45deg);
  }

  .inner-oval {
    align-items: center;
    border: 2px solid white;
    width: 150px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
  }

  .circle1 {
    border: 2px solid white;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    animation: zoom 3s linear 0s infinite;
  }

  .circle2 {
    border: 2px solid white;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    animation: zoom 3s linear 0s infinite;
    animation-delay: 1s;
  }

  .circle3 {
    border: 2px solid white;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    animation: zoom 3s linear 0s infinite;
    animation-delay: 2s;
  }

  .progress {
    width: 60%;
    margin-top: 100px;
  }

  @keyframes zoom {
    0% {
      background-color: black;
    }

    50% {
      background-color: white;
    }

    100% {
      background-color: black;
    }
  }

  @keyframes blink {
    0% {
      background-color: #000000ff;
    }

    50% {
      background-color: #000000aa;
    }

    100% {
      background-color: #000000ff;
    }
  }
}
