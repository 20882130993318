.circle-spin-3 {
  --size: 24px;
  --stroke-width: calc(var(--size) / 6);
  --color: currentColor;
  --animation-timing-function: linear;
  --animation-duration: 1s;
  width: var(--size);
  height: var(--size);
  border-width: var(--stroke-width);
  border-style: solid;
  border-color: var(--color) transparent var(--color) transparent;
  border-radius: 50%;
  transform: rotate(0deg);
  animation: var(--animation-timing-function) var(--animation-duration) infinite circle-spin-3-animation;
}

@keyframes circle-spin-3-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
