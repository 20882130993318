.bg-grad-blue {
  background-image: linear-gradient(to right, #00c6ff 0%, #0072ff 51%, #00c6ff 100%);
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: var(--bs-dark) !important;
  font-weight: 500;
  // box-shadow: 0 0 20px #eee;
  border-radius: 50%;
}

.bg-grad-blue:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.bg-grad-white {
  background-image: linear-gradient(to right, #f8f9fa 0%, #ffffff 51%, #ece9e6 100%);
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  border-radius: 10px;
}

.bg-grad-white:hover {
  background-position: right center;
  color: #000;
  text-decoration: none;
}

.bg-green {
  background-color: #064431;
}

.bg-grad {
  background-image: linear-gradient(to right, #ffb347 0%, #ffcc33 51%, #ffb347 100%);
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  border-radius: 10px;
}

.bg-grad:hover {
  background-position: right center;
  color: #000;
  text-decoration: none;
}
