[data-bs-theme="dark"] {
  .navbar {
    background-color: var(--bs-dark) !important;
  }

  .ecosystem-container {
    .card {
      background-color: rgba($color: #bebebe, $alpha: 0.1) !important;
    }
  }

  .main {
    background-image: linear-gradient(to right, var(--bs-dark), rgb(26, 24, 24)) !important;
    color: var(--bs-light);
  }

  section.bots-container {
    .text-dark {
      color: var(--bs-light) !important;
    }
  }
}
