//Fonts Used- Rubik
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 600;
}

h4,
h5,
h6 {
  font-weight: 400;
}

p,
span,
a,
li,
label,
input,
textarea,
select,
option,
button,
div {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
}

.navbar {
  // background-image: linear-gradient( to left, #0f8d67, #0a4d24);
  border-bottom: 2px solid white;

  .navbar-brand {
    .logo-img {
      height: 60px;
    }
  }

  .active {
    color: white !important;
    opacity: 1 !important;
  }

  .nav-item:hover {
    opacity: 1 !important;
  }

  .blunties-link-item:hover {
    i {
      transform: scale(1.2);
    }
  }

  .dropdown {
    .dropdown-item {
      color: #000;

      &:hover {
        background-color: #0f8d67;
        color: white !important;
      }
    }

    .active-dropdown {
      background-color: #0f8d67;
      color: white !important;
    }
  }
}

.footer {
  width: 100%;
  padding: 40px 100px;
  background-color: rgba(#000000, 0.1);
  opacity: 0;
  transition: height 0.3s ease, opacity 0.3s ease;

  @media screen and (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  .logo-img {
    height: 80px;
  }

  span.sub-header {
    display: block;
    padding-bottom: 20px;
  }

  span.header {
    font-size: 1.5rem;
    font-weight: bolder;
    display: block;
  }

  span#news-letter-addon {
    margin-right: 20px;
  }
}

.footer.show-footer {
  opacity: 1;
  transition: height 0.3s ease, opacity 0.3s ease;
}

section.home-container {
  section.hero-container {
    .hero-section-links {
      .fa-2x {
        font-size: 1.3rem;
      }

      .hero-link-btn {
        width: 60px;
        height: 60px;
        transition: transform 1s;
      }

      .hero-link-btn:hover {
        transform: rotate(360deg);
        animation: none;
        box-shadow: 0px 5px 10px 5px rgba(0, 114, 255, 0.4);
      }

      .hero-link-magic-btn {
        border-radius: 10px;
      }

      .hero-link-magic-btn:hover {
        transform: scale(1.05);
        box-shadow: 0px 5px 10px 5px rgba(25, 135, 84, 0.4);
      }
    }
  }
}

section.ecosystem-container {
  .card {
    .card-title {
      text-transform: uppercase;
    }

    p.card-text {
      span {
        cursor: pointer;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown {
      .dropdown-menu {
        li a {
          &:hover {
            background-color: var(--bs-light);
            color: var(--bs-dark) !important;
          }
        }
      }
    }
  }
}

section.partners-container {
  .card {
    cursor: pointer;
  }

  .card:hover {
    box-shadow: 0 0 20px #eee;
    transform: scale(1.05);
    transition: transform 0.2s ease;
  }
}

section.community-container {
  .card {
    cursor: pointer;
  }

  .card:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease;
  }
}

section.objectives-container {
  .card {
    cursor: pointer;

    button {
      border: 0;
      border-left: 5px solid var(--bs-warning);
      border-radius: 5px;
    }

    .selected {
      transition: background-color 1s ease;
      background-color: var(--bs-warning);
      color: black;
      border-left-color: var(--bs-warning);

      i {
        color: black !important;
      }
    }
  }
}

section.team-container {
  .team-member-name {
    &:hover {
      background-color: var(--bs-warning);
      border-radius: 10px;
      color: black !important;
    }
  }
}

.main {
  color: var(--bs-dark);
  background-image: linear-gradient(to right, #0b6938, #08401e);
}

section.profile-container {
  .table {
    tr {
      td {
        background: rgba($color: var(--bs-dark), $alpha: 0);
        border: 0;
      }
    }
  }
  .discord-owner-container {
    .more-dropdown {
      .dropdown-toggle::after {
        content: none; /* This will remove the arrow icon */
      }
      li:hover {
        background-color: var(--bs-success);
        color: var(--bs-light);
      }
    }
  }
}

section.admin-container {
  margin-top: 70px;
  padding-top: 20px;

  .table {
    td {
      background: rgba($color: var(--bs-dark), $alpha: 0);
      border: 0;

      .dropdown {
        .dropdown-menu {
          li {
            &:hover {
              background-color: var(--bs-success);
              color: var(--bs-light) !important;
            }
          }
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.text-blink {
  animation: blinker 0.8s linear infinite;

  @keyframes blinker {
    60% {
      opacity: 0.3;
    }
  }
}

.profile-container,
.admin-container {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
    transition: opacity 0.3s ease;
    z-index: 1000;
  }

  .modal-overlay.show {
    display: block;
    opacity: 1;
  }
}

.services-container {
  margin-top: 80px;
}

section.hotbox-home {
  margin-top: 5rem;
  .layout-container {
    display: flex;
    min-height: 100vh;
    overflow: hidden;
  }

  .sidebar {
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
    .sidebar-nav {
      li {
        color: white;
      }
      .nav-item:hover {
        // @extend .btn-filled;
        color: inherit;
      }
    }
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #333;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #111;
    }
  }

  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #0d6efd, $alpha: 0.4);
    transition: opacity 0.3s ease;
    // z-index: 2;
    border-radius: var(--bs-card-border-radius);
  }
  @media (max-width: 767px) {
    .sidebar {
      display: none;
    }

    .sidebar-open {
      display: block;
      animation: fadeIn 0.3s ease-in-out;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  .hotbox {
    .header {
      h5 {
        color: #ccc !important;
      }
    }
  }

  .hotbox-content {
    background-image: url("../../public/assets/utilities/hotbox-bg.gif");
    // Specify the background color (adjust the rgba values as needed)
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    // Additional styles for the background
    object-fit: contain;
    background-size: cover;
    background-position: center;
  }
}

.btn-hover-left-bottom {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
